import TermsAndConditions from "./terms-and-conditions"
import { Box, Typography, Card, Link } from '@mui/material';
import {resources} from "src/assets/resources/resources";

export default function Resources(){
    return (
        <Box
          sx={{
            mt: { xl: "15vh", lg: "3vh" },
            display: {
              xs: "none",
              sm: "none",
              md: "flex",
              lg: "flex",
              xl: "flex",
            },
            flexDirection: "column",
            gap: "none",
          }}
        >
          <Typography
            sx={{
              fontSize: {
                xs: "1rem",
                sm: "1rem",
                lg: "1rem",
                xl: "1rem",
                md: "0.8rem",
              },
              fontWeight: "bold",
            }}
          >
            Resources
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: 3,
              flexWrap: { xs: "wrap", sm: "wrap", lg: "nowrap", md: "nowrap" },
              marginLeft: { sm: "5vh", xs: "2.5vh" },
            }}
          >
            {resources.map((resource, index) => (
              <Box
                key={index}
                sx={{
                  cursor: "pointer",
                  flex: "1",
                  maxWidth: { lg: "calc(33.33% - 24px)" },
                  marginTop: "0.8rem",
                }}
              >
              <Link href={resource.link} target="__blank">
                <Card
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    "&:hover": { boxShadow: 3 },
                    transition: "box-shadow 0.3s",
                    height: {
                      xs: "7rem",
                      sm: "6rem",
                      lg: "6rem",
                      xl: "6rem",
                      md: "5rem",
                    },
                    width: {
                      xs: "7.2rem",
                      sm: "7.2rem",
                      lg: "auto",
                      md: "6rem",
                    },
                  }}
                >
                  <resource.icon
                    sx={{
                      mx: "auto",
                      color: "#DC4C3E",
                      fontSize: { xs: 20, sm: 20, lg: 20, xl: 20, md: 12 },
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      mt: 2,
                      fontSize: {
                        xs: "0.7rem",
                        sm: "0.7rem",
                        lg: "0.7rem",
                        xl: "0.7rem",
                        md: "0.5rem",
                      },
                    }}
                  >
                    {resource.title}
                  </Typography>
                </Card>
                </Link>
              </Box>
            ))}
          </Box>

          {/* terms and conditions section */}
          <TermsAndConditions/>
        </Box>
    )
}