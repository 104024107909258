import {
  Star as StarIcon,
  Rocket as RocketIcon,
  Storage as DatabaseIcon,
  Lightbulb as LightbulbIcon,
  Shield as ShieldIcon,
  Book as BookOpenIcon,
} from "@mui/icons-material";

export const resources = [
  {
    icon: RocketIcon,
    title: "How do I sign up for AceCloud?",
    link: "https://docs.acecloudhosting.com/knowledge-base/account/how-do-i-sign-up-for-ace-public-cloud",
  },
  {
    icon: DatabaseIcon,
    title: "How to create a compute instance?",
    link: "https://docs.acecloudhosting.com/knowledge-base/compute",
  },
  {
    icon: LightbulbIcon,
    title: "How can I buy service from AceCloud?",
    link: "https://docs.acecloudhosting.com/knowledge-base/store",
  },
  {
    icon: ShieldIcon,
    title: "Explore our knowledge base section",
    link: "https://docs.acecloudhosting.com/knowledge-base",
  },
  {
    icon: BookOpenIcon,
    title: "How to Manage your Buckets?",
    link: "https://docs.acecloudhosting.com/knowledge-base/s3-storage",
  },
];
