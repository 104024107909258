import { NextPageContext } from "next/types";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  FormLabel,
  FormHelperText,
} from "@mui/material";
import {
  Mail as MailIcon,
  Lock as LockIcon,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { yupResolver } from "@hookform/resolvers/yup";

import Image from "next/image";
import Link from "next/link";

import BlankLayout from "src/@core/layouts/BlankLayout";
import themeConfig from "src/configs/themeConfig";
import { useForm, Controller } from "react-hook-form";
import { loginSchema } from "src/schema/auth/loginSchema";

import { AuthContext } from "src/context/AuthContext";
import { useState, useEffect, useContext, ReactNode } from "react";
import { AxiosError } from "axios";
import showToast from "src/utils/notifications";
import { handleLoading } from "src/configs/helper";
import Resources from "src/components/login/resources";
import ResourcesMobileView from "src/components/login/resources-mobile-view";
import { getRoute } from "src/routes";

const defaultValues = {
  email: "",
  password: "",
};

export default function Component() {
  const [remember, setRemember] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  const { login } = useContext(AuthContext);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(loginSchema),
  });

  useEffect(() => {
    handleLoading(loading);
  }, [loading]);

  const handleFormSubmit = async (data: {
    email: string;
    password: string;
  }) => {
    setLoading(true);
    setDisabled(true);
    try {
      login(data, (error: any) => {
        setDisabled(false);
        setLoading(false);
        if (
          error instanceof AxiosError &&
          error.response?.status === 401 &&
          error.response.data?.message
        ) {
          showToast("error", error.response.data.message);
        } else {
          showToast("error", "Invalid credentials");
        }
      });
    } catch (err: unknown) {
      const error = err as AxiosError | Error;
      showToast(
        "error",
        error.message ??
        "Unable to process the request, please try again later",
      );
    } finally {
      setDisabled(false);
      setLoading(false);
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
        bgcolor: "white",
        // maxHeight: { xs: "100%", md: "100vh", lg: "100vh" },
        // overflow: "hidden",
      }}
    >
      {/* Left Section */}
      <Box
        sx={{
          display: { xs: "none", md: "block", lg: "block" },
          width: { lg: "35%", xl: "35%", md: "35%" },
          position: "relative",
          // overflow: "hidden",
          height: "auto",
          // background:"green"
        }}
      >
        <Box
          sx={{
            position: "absolute",
            inset: 0,
            overflow: "hidden",
            borderBottomRightRadius: "140px",
          }}
        >
          <Image
            src={`${themeConfig.imageBasePath}/images/login-bg.png`}
            alt="Professional in orange shirt"
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            priority
          />
          <Box
            sx={{
              position: "absolute",
              inset: 0,
              background:
                "linear-gradient(0deg, #6A1700 0%, rgba(0, 0, 0, 0) 60%)",
            }}
          ></Box>
        </Box>
        <Box
          sx={{
            position: "absolute",
            inset: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            p: 6,
          }}
        >
          <Box
            sx={{
              width: { xs: 40, lg: 40, xl: 70 },
              height: { xs: 40, lg: 40, xl: 70 },
              position: "relative",
            }}
          >
            <Image
              src={themeConfig.appLogoAlternativePath}
              alt="Ace Cloud Logo"
              layout="fill"
              objectFit="contain"
            />
          </Box>

          {/* <Testimonials /> */}

          <Box
            sx={{ color: "white", spaceY: 2, width: { xs: "auto", lg: "95%" } }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  mb: 2,
                  color: "white",
                  fontSize: {
                    xs: "1rem",
                    lg: "1.5rem",
                    xl: "1.5rem",
                    md: "1.5rem",
                  },
                }}
              >
                High Performance Accelerated Public Cloud
              </Typography>
              <Typography
                sx={{
                  mb: 4,
                  color: "white",
                  fontSize: {
                    xs: "0.74rem",
                    lg: "0.74rem",
                    xl: "1rem",
                    md: "0.6rem",
                  },
                }}
              >
                Transform your business with the highly scalable and secure
                AceCloud offerings, including Network, GPU, Storage, Compute,
                Kubernetes, IaaS, Licenses, Database, and Firewall.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Right Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          p: 6,
          width: { xs: "100%", sm: "100%", lg: "60%", md: "60%" },
          position: "relative",
          ml: { lg: 10, xl: 10, md: 0 },
        }}
      >
        <Box
          sx={{
            mx: "auto",
            width: "100%",
            maxWidth: { xs: 350, sm: 400, lg: 500, xl: 550, md: 420 },
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Box sx={{ mb: { xs: 6, sm: 6, md: 4, lg: 6, xl: 6 } }}>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: {
                  xs: "1rem",
                  sm: "1.4rem",
                  lg: "1.4rem",
                  xl: "1.4rem",
                  md: "1rem",
                },
              }}
            >
              Welcome back.
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "0.8rem",
                  sm: "0.8rem",
                  lg: "0.8rem",
                  xl: "0.8rem",
                  md: "0.6rem",
                },
                color: "black",
              }}
            >
              Please login to your account!
            </Typography>
          </Box>

          <form
            onSubmit={handleSubmit(handleFormSubmit)}
            style={{ display: "flex", flexDirection: "column", gap: 14 }}
          >
            <FormLabel
              htmlFor="email"
              sx={{
                marginBottom: { xs: -2, sm: -2, md: -3, lg: -2, xl: -2 },
                fontSize: {
                  xs: "0.8rem",
                  sm: "0.8rem",
                  lg: "0.8rem",
                  xl: "0.8rem",
                  md: "0.6rem",
                },
              }}
            >
              Email
            </FormLabel>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  id="email"
                  placeholder="Enter your email"
                  // error={Boolean(errors.email)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailIcon
                          sx={{
                            color: "#DC4C3E",
                            height: {
                              xs: "1rem",
                              sm: "1rem",
                              lg: "1rem",
                              xl: "1rem",
                              md: "0.65rem",
                            },
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  variant="outlined"
                  sx={{
                    mb: 0,
                    "& .MuiOutlinedInput-root": {
                      borderColor: "#DC4C3E",
                      "&:hover fieldset": { borderColor: "#DC4C3E" },
                      "&.Mui-focused fieldset": {
                        borderColor: "#DC4C3E",
                        borderWidth: "1px",
                      },
                      height: {
                        xs: "2.5rem",
                        sm: "2.5rem",
                        lg: "2.5rem",
                        xl: "2.5rem",
                        md: "1.65rem",
                      },
                      width: "100%",
                    },
                    "& .MuiOutlinedInput-input::placeholder": {
                      fontSize: {
                        xs: "0.75rem",
                        md: "0.65rem",
                        lg: "0.75rem",
                        xl: "0.75rem",
                        sm: "0.75rem",
                      },
                    },
                    "& .MuiInputBase-input": {
                      fontSize: {
                        xs: "0.75rem",
                        sm: "0.75rem",
                        lg: "0.7rem",
                        xl: "0.7rem",
                        md: "0.65rem",
                      },
                    },
                  }}
                />
              )}
            />
            {errors.email && (
              <FormHelperText sx={{ mt: -2, color: "error.main" }}>
                {errors.email.message}
              </FormHelperText>
            )}

            <FormLabel
              htmlFor="password"
              sx={{
                marginBottom: { xs: -2, sm: -2, md: -3, lg: -2, xl: -2 },
                fontSize: {
                  xs: "0.8rem",
                  sm: "0.8rem",
                  lg: "0.8rem",
                  xl: "0.8rem",
                  md: "0.6rem",
                },
              }}
            >
              Password
            </FormLabel>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  id="password"
                  label=""
                  type={showPassword ? "text" : "password"}
                  // error={Boolean(errors.password)}
                  placeholder="Enter your password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon
                          sx={{
                            color: "#DC4C3E",
                            height: {
                              xs: "1rem",
                              sm: "1rem",
                              lg: "1rem",
                              xl: "1rem",
                              md: "0.65rem",
                            },
                          }}
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOff
                              sx={{
                                height: {
                                  xs: "1rem",
                                  sm: "1rem",
                                  lg: "1rem",
                                  xl: "1rem",
                                  md: "0.65rem",
                                },
                              }}
                            />
                          ) : (
                            <Visibility
                              sx={{
                                height: {
                                  xs: "1rem",
                                  sm: "1rem",
                                  lg: "1rem",
                                  xl: "1rem",
                                  md: "0.65rem",
                                },
                              }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderColor: "#DC4C3E",
                      "&:hover fieldset": { borderColor: "#DC4C3E" },
                      "&.Mui-focused fieldset": {
                        borderColor: "#DC4C3E",
                        borderWidth: "1px",
                      },
                      height: {
                        xs: "2.5rem",
                        md: "1.65rem",
                        lg: "2.5rem",
                        xl: "2.5rem",
                        sm: "2rem",
                      },
                      width: "100%",
                    },
                    "& .MuiOutlinedInput-input::placeholder": {
                      fontSize: {
                        xs: "0.75rem",
                        md: "0.65rem",
                        lg: "0.75rem",
                        xl: "0.75rem",
                        sm: "0.75rem",
                      },
                    },
                    "& .MuiInputBase-input": {
                      fontSize: {
                        xs: "0.75rem",
                        sm: "0.75rem",
                        lg: "0.7rem",
                        xl: "0.7rem",
                        md: "0.65rem",
                      },
                    },
                  }}
                />
              )}
            />
            {errors.password && (
              <FormHelperText sx={{ mt: -2, color: "error.main" }}>
                {errors.password.message}
              </FormHelperText>
            )}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: { md: -3 },
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={remember}
                    size="small"
                    onChange={(e) => setRemember(e.target.checked)}
                  />
                }
                label="Remember"
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: {
                      xs: "0.75rem",
                      sm: "0.75rem",
                      lg: "0.75rem",
                      xl: "0.75rem",
                      md: "0.65rem",
                    },
                  },
                }}
              />
              <Button
                href="https://customer.acecloudhosting.com/index.php?rp=/password/reset"
                sx={{
                  fontSize: {
                    xs: "0.75rem",
                    sm: "0.75rem",
                    lg: "0.75rem",
                    xl: "0.75rem",
                    md: "0.6rem",
                  },
                  "&:hover": { color: "#DC4C3E" },
                }}
              >
                Forgot password?
              </Button>
            </Box>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={disabled}
              sx={{
                bgcolor: "#DC4C3E",
                "&:hover": loading ? {} : { bgcolor: "#DC4C3E90" },
                fontSize: {
                  xs: "0.75rem",
                  sm: "0.75rem",
                  lg: "0.75rem",
                  xl: "0.75rem",
                  md: "0.65rem",
                },
                height: {
                  xs: "2rem",
                  sm: "2rem",
                  lg: "2rem",
                  xl: "2rem",
                  md: "1.65rem",
                },
              }}
            >
              Login
            </Button>

            <Box display="flex" flexDirection="row" justifyContent="center">
              <Typography
                variant="body2"
                sx={{
                  textAlign: "center",
                  fontSize: {
                    xs: "0.75rem",
                    sm: "0.75rem",
                    lg: "0.75rem",
                    xl: "0.75rem",
                    md: "0.6rem",
                  },
                  color: "gray.600",
                }}
              >
                {"Don't have an account? "}
              </Typography>
              <Link href={getRoute("register")}>
                <Typography
                  sx={{
                    color: "#DC4C3E",
                    textDecoration: "none",
                    fontSize: {
                      xs: "0.75rem",
                      sm: "0.75rem",
                      lg: "0.75rem",
                      xl: "0.75rem",
                      md: "0.6rem",
                    },
                    ml: 2,
                  }}
                >
                  Sign up
                </Typography>
              </Link>
            </Box>
          </form>
        </Box>

        <Resources />

        {/* Resources Section for xs and sm screen sizes */}
        <ResourcesMobileView />
      </Box>
    </Box>
  );
}

Component.getLayout = (page: ReactNode) => <BlankLayout>{page}</BlankLayout>;

Component.guestGuard = true;

Component.getInitialProps = async ({
  query,
}: NextPageContext): Promise<any> => {
  return { query };
};
