import { Box, Typography, Link, Card, Button } from '@mui/material';
import React from 'react';
import { useState,useEffect } from 'react';
import {resources} from "src/assets/resources/resources";
import TermsAndConditions from 'src/components/login/terms-and-conditions';

export default function ResourcesMobileView(){
    const [currentResource, setCurrentResource] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
          setCurrentResource((prev) => (prev + 1) % resources.length);
        }, 3000); // Change resource every 3 seconds
    
        return () => clearInterval(interval);
      }, []);
    return (
        <Box
            sx={{
                mt: { xs: "5vh", sm: "5vh" },
                display: {
                    xs: "flex",
                    sm: "flex",
                    md: "none",
                    lg: "none",
                    xl: "none",
                },
                flexDirection: "column",
                gap: "none",
            }}
        >
            <Typography
                sx={{
                    fontSize: { xs: "1rem", sm: "1rem" },
                    fontWeight: "bold",
                }}
            >
                Resources
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 2,
                    mt: 2,
                }}
            >
                <Link href={resources[currentResource].link} target="__blank">
                    <Card
                        sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            "&:hover": { boxShadow: 3 },
                            transition: "box-shadow 0.3s",
                            width: "100%",
                            maxWidth: "300px",
                        }}
                    >
                        {React.createElement(resources[currentResource].icon, {
                            sx: {
                                mx: "auto",
                                color: "#DC4C3E",
                                fontSize: { xs: 20, sm: 20 },
                            },
                        })}
                        <Typography
                            variant="body2"
                            sx={{
                                mt: 2,
                                fontSize: { xs: "0.7rem", sm: "0.7rem" },
                            }}
                        >
                            {resources[currentResource].title}
                        </Typography>
                    </Card></Link>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mt: 2 }}>
                {resources.map((_, i) => (
                    <Box
                        zIndex={10}
                        key={i}
                        sx={{
                            width: { xs: 10, sm: 10, md: 8, lg: 10, xl: 10 }, // Size of the dots
                            height: { xs: 10, sm: 10, md: 8, lg: 10, xl: 10 }, // Size of the dots
                            borderRadius: "50%", // Circular shape
                            bgcolor:
                                i === currentResource
                                    ? "#DC4C3E"
                                    : "rgba(255, 255, 255, 0.5)",
                            border: "1px solid #DC4C3E", // Red border for all dots
                            cursor: "pointer", // Change cursor to pointer to indicate clickable
                        }}
                        onClick={() => setCurrentResource(i)} // Set the current testimonial on click
                    />
                ))}
            </Box>
            <TermsAndConditions/>

        </Box>
    )
}