import { emailRegex } from "src/configs/constants";
import * as yup from "yup";

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .required("email is required")
    .min(5, "email must contain at least 5 characters")
    .max(50, "email must contain at most 50 characters")
    .matches(emailRegex, "invalid email"),
  password: yup.string().required("password is required"),
});
