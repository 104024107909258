import { Typography, Box, Button } from "@mui/material";

import appConfig from "src/configs/app";

export default function TermsAndConditions() {
  return (
    <Box
      position="absolute"
      bottom={10}
      left="50%"
      width="100%"
      sx={{ transform: "translateX(-50%)" }}
    >
      <Box
        sx={{
          display: {
            xl: "flex",
            md: "flex",
            lg: "flex",
            sm: "none",
            xs: "none",
          },
          flexDirection: "column",
          gap: "none",
          // borderTop: 1
        }}
      >
        <Box
          sx={{
            mt: { lg: 2, md: 2, xl: 4 },
            textAlign: "center",
            color: "gray.600",
            fontSize: "0.5rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: { xs: "column", lg: "row", md: "row" },
          }}
        >
          <Typography
            sx={{
              fontSize: {
                xs: "0.5rem",
                md: "0.35rem",
                lg: "0.5rem",
                xl: "0.6rem",
              },
            }}
          >
            Copyright © {new Date().getFullYear()} Real Time Cloud Services
            LLC. All Rights Reserved.
          </Typography>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              href={String(appConfig.termsAndConditionsUrl)}
              target="_blank"
              sx={{
                color: "gray.600",
                fontSize: {
                  xs: "0.5rem",
                  md: "0.35rem",
                  lg: "0.5rem",
                  xl: "0.6rem",
                },
                textDecoration: "none",
              }}
            >
              Terms & Conditions
            </Button>
            {" | "}
            <Button
              href={String(appConfig.privacyPolicyUrl)}
              target="_blank"
              sx={{
                color: "gray.600",
                fontSize: {
                  xs: "0.5rem",
                  md: "0.35rem",
                  lg: "0.5rem",
                  xl: "0.6rem",
                },
                textDecoration: "none",
              }}
            >
              Privacy Policy
            </Button>
            {" | "}
            <Button
              href={String(appConfig.supportUrl)}
              target="_blank"
              sx={{
                color: "gray.600",
                fontSize: {
                  xs: "0.5rem",
                  md: "0.35rem",
                  lg: "0.5rem",
                  xl: "0.6rem",
                },
                textDecoration: "none",
              }}
            >
              Get Support
            </Button>
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          mt: { xs: "5vh", sm: "5vh" },
          display: {
            xs: "flex",
            sm: "flex",
            md: "none",
            lg: "none",
            xl: "none",
          },
          flexDirection: "column",
          gap: "none",
        }}
      >
        {/* terms and conditions section */}
        <Box
          sx={{
            mt: 2,
            textAlign: "center",
            color: "gray.600",
            fontSize: "0.5rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography sx={{ fontSize: { xs: "0.5rem", sm: "0.5rem" } }}>
            Copyright © {new Date().getFullYear()} Real Time Cloud Services
            LLC. All Rights Reserved.
          </Typography>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              href={appConfig.termsAndConditionsUrl}
              target="_blank"
              sx={{
                color: "gray.600",
                fontSize: { xs: "0.5rem", sm: "0.5rem" },
                textDecoration: "none",
              }}
            >
              Terms & Conditions
            </Button>
            {" | "}
            <Button
              href={appConfig.privacyPolicyUrl}
              target="_blank"
              sx={{
                color: "gray.600",
                fontSize: { xs: "0.5rem", sm: "0.5rem" },
                textDecoration: "none",
              }}
            >
              Privacy Policy
            </Button>
            {" | "}
            <Button
              href={appConfig.supportUrl}
              target="_blank"
              sx={{
                color: "gray.600",
                fontSize: { xs: "0.5rem", sm: "0.5rem" },
                textDecoration: "none",
              }}
            >
              Get Support
            </Button>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
